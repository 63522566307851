<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer padding="normal">
        <FlexItem padding="normal" :grow="true">
          <p>No content.</p>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
export default {}
</script>
